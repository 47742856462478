import React from "react"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Laptop from "../components/laptop/laptop"
import Phone from "../components/phone/phone"
import PhoneImage from "../images/iphone-image"
import LaptopImage from "../images/laptop-image"
import { Link } from "gatsby"
import "../page-styles/index.scss"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <div className="container">
        <div className="screenshots">
          <Laptop>
            <LaptopImage />
          </Laptop>
          <Phone>
            <PhoneImage />
          </Phone>
        </div>
        <div className="content">
          <div>
            <h1>Bistrobo</h1>
            <p>
              If you own a Food truck in Austin and want to take orders online,
              you need to give Bistrobo a try. Bistrobo puts your business
              first, bringing you and your customers closer together rather than
              getting in the way.
            </p>
            <div className="list-features">
              <h3>With Bistrobo you can...</h3>
              <ul>
                <li>Give your business a beautiful online menu</li>
                <li>Easily manage your menu from your phone or computer</li>
                <li>Get payments directly sent to your Square account</li>
              </ul>
            </div>
            <Link to="/form" className="btn">
              Free 30 Day Trial
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
